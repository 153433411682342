export const feetToMeters = (quantity) => quantity / 3.281;
export const metersToFeet = (quantity) => quantity * 3.281;


export const kgToPounds = (kg) => kg * 2.20462;
export const poundsToKg = (pounds) => pounds / 2.20462;

export const metersPerSecondToKMPH = (metersPerSecond) => metersPerSecond * 3.6;
export const metersPerSecondToKmphDisplay = (metersPerSecond) => `${metersPerSecondToKMPH(metersPerSecond)} km/h`
export const metersPerSecondToMPH = (metersPerSecond) => metersPerSecond * 2.237
export const metersPerSecondToMphDisplay = (metersPerSecond) => `${metersPerSecondToMPH(metersPerSecond)} mph`
// export const weightConversion = (targetUnit, amount) => Math.round(pounds / 2.20462);

export const metersToKm = (meters) => meters / 1000;
export const metersToMiles = (meters) => meters / 1609;
