import { L } from 'harmony-language';
import React from 'react';
import { getTransLocationTypeLabel } from '../../../constants/constants';
import { Driver, Load, OrganizationLocation, Stop } from '../../../types';
import { localTime } from '../../../utils/date-time-utils';
import { LoadCargo2 } from '../../shared/load-table/load-table-cells';

interface LiveMapRouteTooltipProps {
    driver?: Driver;
    nextStop?: Stop & { displayName: string, organizationLocation: OrganizationLocation };
    load: Load;
    convertFromGramsDisplay: (g: number) => string;
    grossWeight: string | undefined;
    speedDisplay: string;
}

export const LiveMapRouteTooltip: React.FC<LiveMapRouteTooltipProps> = (props) => {
    const { load, driver, nextStop, convertFromGramsDisplay, grossWeight, speedDisplay } = props;

    return (
        <div className='route-info-tooltip'>
            <div className='route-info-tooltip-order'>
                {load && <div className='route-info-tooltip-order-section'>
                    <span className='section-title'>{L.load()}</span>
                    <span className='section-focus'>{load.id}</span>
                    <LoadCargo2 load={load} convertFromGramsDisplay={convertFromGramsDisplay} />
                </div>
                }
                <div className='route-info-tooltip-order-section'>
                    <span className='section-title'>{L.driver()}</span>
                    <span className='section-focus'>{driver?.name}</span>
                    <span className='section-detail'>{driver?.phone}</span>
                </div>
                {nextStop && <div className='route-info-tooltip-order-section'>
                    <span className='section-title'>{L.nextStop()}</span>
                    <span className='section-focus'>{nextStop?.displayName}</span>
                    <span
                        className='section-detail'>{nextStop?.organizationLocation && getTransLocationTypeLabel(nextStop?.organizationLocation?.organizationLocationTypeId)}</span>
                </div>
                }
            </div>
            <div className='route-info-tooltip-timeline'>
                <div className='timeline-bar' />
                <div className='timeline-bottom-section'>
                    <div>
                        <div>{`${L.speed()}: ${speedDisplay}`}</div>
                        <div>{`${L.weight()}: ${grossWeight}`}</div>
                    </div>
                    <div className='timeline-next-stop-address'>
                        <span>{nextStop?.organizationLocation?.location?.address?.address1}</span>
                        <span>{`${nextStop?.organizationLocation?.location?.address?.city}, ${nextStop?.organizationLocation?.location?.address?.state} ${nextStop?.organizationLocation?.location?.address?.postalCode}`}</span>
                        <span>{L.dockTime(localTime(nextStop?.arrivalTime))}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
